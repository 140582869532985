import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import vimeoData from "./vimeo.json";

function Videos() {
  const { videoId } = useParams();
  const [vimeoId, setVimeoId] = useState();
  useEffect(() => {
    console.log(videoId);
    const videos = JSON.parse(JSON.stringify(vimeoData));
    const guid = videos.filter((f) => f.guid.trim() === videoId)[0]?.uuid ?? null;
    setVimeoId(guid);
  }, [videoId]);
  console.log(vimeoId);
  return (
    <div className="video-container">
      {vimeoId && <iframe className="video-iframe" src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1`} title="video player" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />}
      {!vimeoId && <div className="empty-container"></div>}
    </div>
  );
}

export default Videos;
